// alerts components
import Alert from './alerts/Alert';
import AlertDismissable from './alerts/AlertDismissable';
// main app components
import MainLoader from './app/MainLoader';
import AssetFilter from './app/AssetFilter';
import CollectionFilter from './app/CollectionFilter';
import CollectionPagination from './app/CollectionPagination';
import AuthorizedComponent from './app/AuthorizedComponent';
import WithPopper from './app/WithPopper';
import {
    Filters, Block, HORIZONTAL, VERTICAL, OVERLAY,
} from './app/filters/Filters';
import Portal from './app/portal';
// button components
import ButtonCounter from './buttons/buttonCounter/ButtonCounter';
import MultiActionButton from './buttons/multiActionButton/MultiActionButton';
import PlusTextAction from './buttons/plusTextAction/PlusTextAction';
import TrashButton from './buttons/trashButton/TrashButton';
import Button from './buttons/button/Button';
import AddItemButton from './buttons/addItemButton/AddItemButton';
// container components
import PrimaryModal from './containers/PrimaryModal';
import VisibilityToggleComponent from './containers/visibilityToggleComponent/VisibilityToggleComponent';
import InfoGroup from './containers/infoGroup/InfoGroup';
import ConfirmationDialog from './containers/ConfirmationDialog/ConfirmationDialog';
import Modal from './containers/Modals/Modal';
import MODAL_CONSTANTS from './containers/Modals/Constants';
import InformationBlock from './containers/informationBlock/InformationBlock';
// d3 components
import D3Renderer from './d3/D3Renderer';
import Timeline from './d3/timeline';
// display components
import { TelematicField, LabelValue, GaugeValue } from './display/TelematicValue';
// forms components
import DocumentUploadForm from './forms/DocumentUploadForm';
import FileInput from './forms/internal/inputs/FileInput';
// selectors components
import Selector from './selectors/selector/Selector';
import LocationSelector from './selectors/locationSelector/LocationSelector';
import MultiStateSelector from './selectors/multiStateSelector/MultiStateSelector';
import DateSelector from './selectors/dateSelector/DateSelector';
import ComboSelector from './selectors/comboSelector/ComboSelector';
import TimeRangeSelector from './selectors/timeRangeSelector/TimeRangeSelector';
import SwitchSelector from './selectors/switchSelector/SwitchSelector';
import { QUICK_RANGES } from './selectors/quick_ranges';
// filters components
import TimeRangeFilter from './filters/timeRangeFilter/TimeRangeFilter';
import MultiOptionFilter from './filters/multiOptionFilter/MultiOptionFilter';
import VerticalMultiOptionFilter from './filters/verticalMultiOptionFilter/VerticalMultiOptionFilter';

import SelectFilter, { THEMES } from './filters/selectFilter/SelectFilter';
import SearchFilter from './filters/searchFilter/SearchFilter';
import SelectLocationFilter from './filters/selectLocationFilter/SelectLocationFilter';
import FaultCategorySelector from './selectors/faultCategorySelector/FaultCategorySelector';
import DateTimeSelector from './filters/dateTimeSelector/DateTimeSelector';
// tables components
import ColumnChooser from './tables/ColumnChooser';
import DataGrid from './tables/DataGrid';
import FlexTable from './tables/FlexTable';
import Table, { THEMES as TABLE_THEMES } from './tables/table/Table';
// hooks components
import { useResponsiveSize, findBreakPoint } from './hooks/useResponsiveBreakpoints';
import useArrayToDict from './hooks/useArrayToDict';
import usePrevious from './hooks/usePrevious';
// renderers components
import renderers from './renderer';
import Loader from './animations/loader/Loader';

// RailForms components
import FormCheckbox from './forms/RailForms/formCheckbox/FormCheckbox';
import FormDateTime from './forms/RailForms/formDateTime/FormDateTime';
import FormDropdown from './forms/RailForms/formDropdown/FormDropdown';
import FormFile from './forms/RailForms/formFile/FormFile';
import FormManager from './forms/RailForms/FormManager';
import FormTextInput from './forms/RailForms/formTextInput/FormTextInput';
import FormCustomField from './forms/RailForms/FormCustomField/FormCustomField';
import FormFaultCategory from './forms/RailForms/formFaultCategory/FormFaultCategory';
import FormLocationDropdown from './forms/RailForms/formLocationDropdown/FormLocationDropdown';
import MultiDeviceLayout, { LAYOUT } from './app/MultiDeviceLayout';
import CutForm from './forms/RailForms/cutForm/CutForm';
import StepManager from './forms/RailForms/StepManager';
import Step from './forms/RailForms/step/Step';
import FormFieldSwitcher from './forms/RailForms/formFieldSwitcher/FormFieldSwitcher';
import FormBoolean from './forms/RailForms/formBoolean/FormBoolean';
import TextAction from './buttons/textAction/TextAction';
import DetailLayout from './forms/RailForms/DetailLayout';
import FormLabel from './forms/RailForms/formLabel/FormLabel';
import StateTimeline from './display/stateTimeline/StateTimeline';
import TimelineBullet from './display/timelineBullet/TimelineBullet';
import TextDisplay from './display/textDisplay/TextDisplay';
import EditButton from './buttons/editButton/EditButton';
import CancelButton from './buttons/cancelButton/CancelButton';
import FocusableFrame from './frames/focusableFrame/FocusableFrame';
import NumberedTitle from './headers/numberedTitle/NumberedTitle';
import ModalTitle from './headers/modalTitle/ModalTitle';
import DetailTitle from './headers/detailTitle/DetailTitle';
import DetailFrame from './frames/detailFrame/DetailFrame';
import Checkbox from './buttons/checkbox/Checkbox';
import NoDataDisplay from './display/noDataDisplay/NoDataDisplay';
import CustomFieldDisplay from './display/customFieldDisplay/CustomFieldDisplay';
import LineGraph from './graphs/lineGraph/LineGraph';
import MergeController from './graphs/mergeController/MergeController';

import InputsWrapper from './forms/RailForms/InputsWrapper';
import DataWrapper from './forms/RailForms/DataWrapper';
import RenderData from './forms/RailForms/DetailRender/RenderData';
import RenderText from './forms/RailForms/DetailRender/RenderText';
import RenderStatus from './forms/RailForms/DetailRender/RenderStatus';
import RenderCustomField from './forms/RailForms/DetailRender/RenderCustomField';
import RenderNoData from './forms/RailForms/DetailRender/RenderNoData';
import RenderDocuments from './forms/RailForms/DetailRender/RenderDocuments';
import FormHotlineCall from './forms/RailForms/formHotlineCall/FormHotlineCall';
import FormCounter from './forms/RailForms/formCounter/FormCounter';
import TabbedFrame from './frames/tabbedFrame/TabbedFrame';
import AsyncConfirmationDialog from './containers/AsyncConfirmationDialog/AsyncConfirmationDialog';
import HeaderModal from './headers/headerModal/HeaderModal';
import FormCounterDoneUsage from './forms/RailForms/formCounterDoneUsage/FormCounterDoneUsage';
import SuggestedBubble from './display/suggestedBubble/SuggestedBubble';
import FormTimeline from './forms/RailForms/formTimeline/FormTimeline';
import FormRestrictions from './forms/RailForms/formRestrictions/FormRestrictions';
import ErrorMessageDisplay from './display/errorMessageDisplay/ErrorMessageDisplay';
import RenderRestrictions from './forms/RailForms/DetailRender/RenderRestrictions';
import RenderCounter from './forms/RailForms/DetailRender/RenderCounter';
import LoadingOverlay from './animations/loadingOverlay/LoadingOverlay';
import RenderCounterDoneUsage from './forms/RailForms/DetailRender/RenderCounterDoneUsage';
import RenderFaultCategory from './forms/RailForms/DetailRender/RenderFaultCategory';
import MultiTextDisplay from './display/multiTextDisplay/MultiTextDisplay';
import FormLabelCategory from './forms/RailForms/formLabelCategory/FormLabelCategory';
import RenderLabelCategory from './forms/RailForms/DetailRender/RenderLabelCategory';
import LoginlessComponent from './app/loginlessComponent';
import WithLoginless from './app/withLoginless';
import FormCaptcha from './forms/RailForms/formCaptcha/FormCaptcha';
import Toast from './frames/toast/Toast';
import LateIcon from './display/lateIcon/LateIcon';
import usePerm from './hooks/usePerm';
import CallHotlineButton from './buttons/callHotlineButton/CallHotlineButton';
import RestrictionCard from './display/restrictionCard/RestrictionCard';
import AssetTrackOnMap from './display/assetTrackOnMap/AssetTrackOnMap';
import useDictToArray from './hooks/useDictToArray';
import useMe from './hooks/useMe';
import AutoTranslatedDisplay from './display/autoTranslatedDisplay/AutoTranslatedDisplay';
import Dialog from './containers/dialog/Dialog';
import HintIcon from './display/hintIcon/HintIcon';
import SwitchFilter from './filters/switchFilter/SwitchFilter';

export default {
    alerts: {
        Alert,
        AlertDismissable,
    },
    animations: {
        Loader,
        LoadingOverlay,
    },
    app: {
        MainLoader,
        AssetFilter,
        CollectionFilter,
        CollectionPagination,
        AuthorizedComponent,
        Filters,
        Block,
        HORIZONTAL,
        VERTICAL,
        OVERLAY,
        Portal,
        WithPopper,
        MultiDeviceLayout,
        LAYOUT,
        LoginlessComponent,
        WithLoginless,
    },
    buttons: {
        ButtonCounter,
        MultiActionButton,
        PlusTextAction,
        TextAction,
        TrashButton,
        Button,
        AddItemButton,
        EditButton,
        CancelButton,
        Checkbox,
        CallHotlineButton,
    },
    containers: {
        PrimaryModal,
        InfoGroup,
        VisibilityToggleComponent,
        ConfirmationDialog,
        AsyncConfirmationDialog,
        InformationBlock,
        Modal,
        MODAL_CONSTANTS,
        Dialog,
    },
    d3: {
        D3TSRenderer: D3Renderer.D3TSRenderer,
        Timeline,
    },
    display: {
        LabelValue,
        GaugeValue,
        TelematicField,
        TextDisplay,
        NoDataDisplay,
        CustomFieldDisplay,
        SuggestedBubble,
        ErrorMessageDisplay,
        MultiTextDisplay,
        LateIcon,
        RestrictionCard,
        AssetTrackOnMap,
        AutoTranslatedDisplay,
        StateTimeline,
        TimelineBullet,
        HintIcon,
    },
    filters: {
        SearchFilter,
        TimeRangeFilter,
        QUICK_RANGES,
        MultiOptionFilter,
        VerticalMultiOptionFilter,
        SelectFilter,
        DateTimeSelector,
        SelectLocationFilter,
        SwitchFilter,
    },
    forms: {
        DocumentUploadForm,
        FileInput,

        FormCheckbox,
        FormCustomField,
        FormDateTime,
        FormDropdown,
        FormFile,
        FormLocationDropdown,
        FormManager,
        FormTextInput,
        CutForm,
        StepManager,
        Step,
        FormFieldSwitcher,
        FormBoolean,
        DetailLayout,
        FormLabel,
        FormFaultCategory,
        FormHotlineCall,
        FormCounter,
        FormCounterDoneUsage,
        FormTimeline,
        FormRestrictions,
        FormLabelCategory,
        FormCaptcha,

        InputsWrapper,
        DataWrapper,
        RenderData,
        RenderText,
        RenderStatus,
        RenderCustomField,
        RenderNoData,
        RenderDocuments,
        RenderRestrictions,
        RenderCounter,
        RenderCounterDoneUsage,
        RenderFaultCategory,
        RenderLabelCategory,
    },
    frames: {
        FocusableFrame,
        DetailFrame,
        TabbedFrame,
        Toast,
    },
    graphs: {
        LineGraph,
        MergeController,
    },
    headers: {
        NumberedTitle,
        ModalTitle,
        DetailTitle,
        HeaderModal,
    },
    hooks: {
        useResponsiveSize,
        findBreakPoint,
        useArrayToDict,
        useDictToArray,
        usePrevious,
        usePerm,
        useMe,
    },
    selectors: {
        Selector,
        DateSelector,
        LocationSelector,
        MultiStateSelector,
        ComboSelector,
        TimeRangeSelector,
        SwitchSelector,
        FaultCategorySelector,
        THEMES,
    },
    tables: {
        ColumnChooser,
        DataGrid,
        FlexTable,
        Table,
        TABLE_THEMES,
    },
    renderers,
};
