import React from 'react';
import { connect } from 'react-redux';
import components from 'components';
import { documentsSelectors, documentsOperations } from 'railfleet_state/ducks/documents';
import { maintenanceEventsOperations } from 'railfleet_state/ducks/maintenance_events';

const { DocumentUploadForm } = components.forms;

const SmallDocumentHolder = (props) => (
    <DocumentUploadForm
        fetchDocumentsForItem={props.fetchDocumentsForEvent}
        deleteDocument={props.deleteDocument}
        updateItem={props.updateEvent}
        setDocumentInStore={props.setDocumentInStore}
        item_id={props.event_id}
        item_key="event"
        documents={props.documents}
        upload_url="/api/document.json"
        count={props.count}
        disabled={props.disabled}
    />
);

const mapSmallDocumentHolderStateToProps = (state, props) => {
    let documents = props.noRedux ? documentsSelectors.getDocuments(state) : documentsSelectors.getDocumentsForEvent(state, props.event_id);
    if (props.noRedux) {
        const filtered = _.filter(documents, (document) => document.event === props.event_id);
        documents = {};
        _.forEach(filtered, (document) => {
            documents[document.id] = document;
        });
    }
    return {
        documents,
    };
};

const mapSmallDocumentHolderDispatchToProps = (dispatch) => ({
    fetchDocumentsForEvent: (event_id) => (
        dispatch(documentsOperations.fetchForEvent(event_id))
    ),
    deleteDocument: (file) => (
        dispatch(documentsOperations.deleteDocument(file))
    ),
    setDocumentInStore: (document) => (
        dispatch(documentsOperations.setDocumentInStore(document))
    ),
    updateEvent: (event) => (
        dispatch(maintenanceEventsOperations.updateMaintenanceEvent(event))
    ),
});

// Utilisé dans la liste des events affiché sur le dashboard
export default connect(
    mapSmallDocumentHolderStateToProps,
    mapSmallDocumentHolderDispatchToProps,
)(SmallDocumentHolder);
