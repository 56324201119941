import React, { useContext, useEffect, useState } from 'react';
import i18n from 'i18next';
import { connect } from 'react-redux';
import components from 'components';
import moment from 'moment-timezone';
import { assetsSelectors } from 'railfleet_state/ducks/assets';
import { locationsSelectors, locationsOperations } from 'railfleet_state/ducks/locations/';
import { maintenanceEventsOperations } from 'railfleet_state/ducks/maintenance_events';
import { documentsSelectors, documentsOperations } from 'railfleet_state/ducks/documents';
import { companiesSettingsSelectors } from 'railfleet_state/ducks/companies_settings';
import { restrictionsSelectors } from 'railfleet_state/ducks/restrictions';
import { countersSelectors } from 'railfleet_state/ducks/counters';
import { operationsSelectors } from 'railfleet_state/ducks/operations';
import { uxSelectors, uxOperations } from 'railfleet_state/ducks/ux';

import './PreventiveEventDetail.scss';
import TextAction from 'components/buttons/textAction/TextAction';
import { COUNTER_UNIT_TRANSLATION } from '../../../../../dashboard/static/constants';
import formatNumber from '../../../utils/formatNumber';
import InterventionHistory from '../../lists/interventionHistory/InterventionHistory';
import ComponentDisplay from '../../../components/display/componentDisplay/ComponentDisplay';
import usePerm from '../../../components/hooks/usePerm';
import { ContextDevice, withMultiDeviceLayout } from '../../../components/app/MultiDeviceLayout';

const { DetailFrame } = components.frames;
const { TextDisplay, CustomFieldDisplay } = components.display;
const { CutForm } = components.forms;
const { MultiDeviceLayout } = components.app;
const { HeaderModal } = components.headers;
const { RenderDocuments, RenderCounter, RenderCounterDoneUsage } = components.forms;
const { EditButton, CancelButton } = components.buttons;

const PreventiveEventDetail = (props) => {
    const [_pushInfos, setPushInfos] = useState(null);
    const hasPerm = usePerm(props.asset_id || null);
    const { device } = useContext(ContextDevice);

    useEffect(() => {
        if (props.push_infos) {
            setPushInfos(props.push_infos);
            props.setPushInfos(null, 'prev detail');
        }
    }, [props.push_infos]);

    useEffect(() => {
        if (props.event_id) props.fetchEventDetails(props.event_id);
    }, [props.event_id]);

    useEffect(() => {
        if (props.maintenance_event && props.maintenance_event.poi && !props.poi.id) {
            props.fetchLocation(props.maintenance_event.poi);
        }
        if (props.maintenance_event && props.maintenance_event.id) {
            props.fetchDocumentsForEvent(props.maintenance_event.id);
        }
    }, [props.maintenance_event]);

    const DueUsageInfo = () => {
        if (!props.remaining_usage) return null;
        const unit = COUNTER_UNIT_TRANSLATION[props.counter_unit] || props.counter_unit;
        let dueUsage = props.due_usage;
        if (dueUsage && typeof dueUsage === 'number') {
            dueUsage = formatNumber(dueUsage);
        }
        return (
            <div>
                <small
                    dangerouslySetInnerHTML={{ // eslint-disable-line react/no-danger
                        __html: i18n.t('due_usage_info', {
                            value: `<b>${formatNumber(props.counter_value)} ${unit}</b>`,
                            remaining_usage: `<b>${formatNumber(props.remaining_usage)} ${unit}</b>`,
                            due_usage: `<b>${dueUsage} ${unit}</b>`,
                            interpolation: { escapeValue: false },
                            avg: `<b>${formatNumber(props.counter_daily_avg)} ${unit}</b>`,
                            reference_date_text: `<b>${moment(props.counter_increment_date).format('DD/MM/YYYY')}</b>`,
                        }),
                    }}
                />
            </div>
        );
    };

    const CancelButtonManager = () => {
        if (props.maintenance_event.next_planned_intervention) return null;
        if (props.maintenance_event.state === 'closed') return null;
        return (
            <CancelButton
                text={i18n.t('Close event')}
                onClick={props.onClickClose}
                disabled={!hasPerm('close_preventive_event')}
            />
        );
    };

    const detailRenderer = () => (
        <div className="preventive-event-refs">
            <div className="preventive-event-ref">
                <span>{i18n.t('Réf')}</span>
                <span>{`M${props.event_id}`}</span>
                {
                    props.is_railnovian_impersonating && (
                        <TextAction
                            onClick={() => {
                                const link = `/admin/maintenance/maintenanceevent/?q=${props.event_id}`;
                                window.open(
                                    link,
                                    '_blank',
                                );
                            }}
                            text={i18n.t('View in admin')}
                        />
                    )
                }
            </div>
        </div>
    );

    const actionsRenderer = () => (
        <>
            {
                props.event_state === 'init'
                    && (
                        <div className="init-event-header-action">
                            <CancelButton
                                text={i18n.t('Discard event')}
                                onClick={() => props.discardEvent(props.event_id)}
                            />
                        </div>
                    )
            }
            <div className="preventive-event-header-actions">
                <CancelButtonManager
                    text={i18n.t('Close event')}
                    onClick={props.onClickClose}
                />
                <EditButton
                    text={i18n.t('Edit event')}
                    onClick={props.onClickEdit}
                    disabled={!hasPerm('edit_preventive_event')}
                />
            </div>
        </>
    );

    const onClickPrevious = () => {
        window.location.href = _pushInfos.from;
    };

    if (!hasPerm('view_preventive_events')) {
        return (
            <div className={`preventive-event-detail ${device}`}>
                <HeaderModal
                    title={i18n.t('Event details')}
                    onCloseModal={props.onCloseModal}
                />
                <div>YOU DO NOT HAVE THE PERM: view_preventive_events</div>
            </div>
        );
    }

    return (
        <div className={`preventive-event-detail ${device}`}>
            <HeaderModal
                title={i18n.t('Event details')}
                detailRenderer={detailRenderer}
                actionsRenderer={!props.readOnly && actionsRenderer}
                onCloseModal={props.onCloseModal}
                previous_link={_pushInfos && onClickPrevious}
            />
            <DetailFrame
                title={i18n.t('General informations')}
            >
                <CutForm
                    device={device}
                >
                    <TextDisplay
                        label={i18n.t('Asset')}
                        data={props.asset.name}
                    />
                    <ComponentDisplay
                        label={i18n.t('Component')}
                        asset_id={props.asset_id}
                        component_id={
                            props.maintenance_event
                            && props.maintenance_event.component
                        }
                    />
                    <TextDisplay
                        label={i18n.t('Maintenance type')}
                        data={props.maintenance_type}
                    />
                    <TextDisplay
                        label={i18n.t('Description')}
                        data={props.maintenance_event.description}
                        large={!!props.maintenance_event.description}
                    />
                </CutForm>
            </DetailFrame>
            {
                props.public_fields && props.public_fields.length > 0
                && (
                    <DetailFrame
                        title={i18n.t('Public')}
                        hide={props.event_state === 'init'}
                    >
                        <CustomFieldDisplay
                            asset_id={props.asset_id}
                            content_type="maintenanceevent"
                            content_options={props.content_options}
                            public
                            data={props.maintenance_event}
                        />
                    </DetailFrame>
                )
            }
            {
                props.private_fields && props.private_fields.length > 0
                && (
                    <DetailFrame
                        title={i18n.t('Private')}
                        private
                        hide={props.event_state === 'init'}
                    >
                        <CustomFieldDisplay
                            asset_id={props.asset_id}
                            content_type="maintenanceevent"
                            content_options={props.content_options}
                            private
                            data={props.maintenance_event}
                        />
                    </DetailFrame>
                )
            }
            {
                props.maintenance_event.counter ? (
                    <>
                        <DetailFrame
                            title={`${i18n.t('Scheduling mode')}: ${i18n.t('counter_scheduling_mode')}`}
                            hide={props.event_state === 'init'}
                        >
                            <CutForm
                                device={device}
                            >
                                <TextDisplay
                                    label={i18n.t('operation')}
                                    data={props.operation_name}
                                />
                                <TextDisplay
                                    label={i18n.t('due date')}
                                    data={moment(props.date_due).format('DD/MM/YYYY HH:mm')}
                                />
                                <TextDisplay
                                    label={i18n.t('date_tolerance')}
                                    data={props.date_tolerance && moment(props.date_tolerance).format('DD/MM/YYYY HH:mm')}
                                />
                                <RenderCounter
                                    id="counter"
                                    label={i18n.t('counter')}
                                    counter_id={props.maintenance_event.counter}
                                />
                                <TextDisplay
                                    label={`${i18n.t('due usage')} ${props.counter_unit}`}
                                    data={formatNumber(props.due_usage)}
                                >
                                    <DueUsageInfo />
                                </TextDisplay>
                                <TextDisplay
                                    label={i18n.t('Date limit')}
                                    data={props.date_limit && moment(props.date_limit).format('DD/MM/YYYY HH:mm')}
                                />
                            </CutForm>
                        </DetailFrame>
                        {
                            props.event_state === 'closed'
                                && (
                                    <DetailFrame
                                        title={i18n.t('Maintenance report')}
                                        hide={props.event_state === 'init'}
                                    >
                                        {
                                            props.public_fields_close
                                        && props.public_fields_close.length > 0
                                            && (
                                                <CustomFieldDisplay
                                                    asset_id={props.asset_id}
                                                    content_type="maintenanceevent"
                                                    content_options={props.content_options_close}
                                                    public
                                                    data={props.maintenance_event}
                                                />
                                            )
                                        }
                                        {
                                            props.private_fields_close
                                        && props.private_fields_close.length > 0
                                            && (
                                                <CustomFieldDisplay
                                                    asset_id={props.asset_id}
                                                    content_type="maintenanceevent"
                                                    content_options={props.content_options_close}
                                                    private
                                                    data={props.maintenance_event}
                                                />
                                            )
                                        }
                                        <CutForm
                                            device={device}
                                        >
                                            <TextDisplay
                                                label={i18n.t('Date closed')}
                                                data={props.date_closed && moment(props.date_closed).format('DD/MM/YYYY HH:mm')}
                                            />
                                            <TextDisplay
                                                label={i18n.t('Closed by')}
                                                data={props.users && props.users[props.maintenance_event.user_closed]?.email}
                                            />
                                            <TextDisplay
                                                label={i18n.t('Observations')}
                                                data={props.rex}
                                                large
                                            />
                                        </CutForm>
                                        {
                                            ['preventive', 'campaign'].includes(props.maintenance_type)
                                            && (
                                                <RenderCounterDoneUsage
                                                    event_id={props.event_id}
                                                    asset_id={props.asset_id}
                                                />
                                            )
                                        }
                                    </DetailFrame>
                                )
                        }
                    </>
                ) : (
                    <>
                        <DetailFrame
                            title={`${i18n.t('Scheduling mode')}: ${i18n.t('no_counter_scheduling_mode')}`}
                            hide={props.event_state === 'init'}
                        >
                            {
                                props.public_fields_close
                                && props.public_fields_close.length > 0
                                    && (
                                        <CustomFieldDisplay
                                            asset_id={props.asset_id}
                                            content_type="maintenanceevent"
                                            content_options={props.content_options_close}
                                            public
                                            data={props.maintenance_event}
                                        />
                                    )
                            }
                            {
                                props.private_fields_close
                                && props.private_fields_close.length > 0
                                    && (
                                        <CustomFieldDisplay
                                            asset_id={props.asset_id}
                                            content_type="maintenanceevent"
                                            content_options={props.content_options_close}
                                            private
                                            data={props.maintenance_event}
                                        />
                                    )
                            }
                            <CutForm
                                device={device}
                            >
                                <TextDisplay
                                    label={i18n.t('operation')}
                                    data={props.operation_name}
                                />
                                <TextDisplay
                                    label={i18n.t('due date')}
                                    data={moment(props.date_due).format('DD/MM/YYYY HH:mm')}
                                />
                                <TextDisplay
                                    label={i18n.t('date_tolerance')}
                                    data={props.date_tolerance && moment(props.date_tolerance).format('DD/MM/YYYY HH:mm')}
                                />
                                <TextDisplay
                                    label={i18n.t('Date limit')}
                                    data={props.date_limit && moment(props.date_limit).format('DD/MM/YYYY HH:mm')}
                                />
                            </CutForm>
                        </DetailFrame>
                        {
                            props.event_state === 'closed'
                                && (
                                    <DetailFrame
                                        title={i18n.t('Maintenance report')}
                                        hide={props.event_state === 'init'}
                                    >
                                        {
                                            props.public_fields_close
                                        && props.public_fields_close.length > 0
                                            && (
                                                <CustomFieldDisplay
                                                    asset_id={props.asset_id}
                                                    content_type="maintenanceevent"
                                                    content_options={props.content_options_close}
                                                    public
                                                    data={props.maintenance_event}
                                                />
                                            )
                                        }
                                        {
                                            props.private_fields_close
                                        && props.private_fields_close.length > 0
                                            && (
                                                <CustomFieldDisplay
                                                    asset_id={props.asset_id}
                                                    content_type="maintenanceevent"
                                                    content_options={props.content_options_close}
                                                    private
                                                    data={props.maintenance_event}
                                                />
                                            )
                                        }
                                        <CutForm
                                            device={device}
                                        >
                                            <TextDisplay
                                                label={i18n.t('Date closed')}
                                                data={props.date_closed && moment(props.date_closed).format('DD/MM/YYYY HH:mm')}
                                            />
                                            <TextDisplay
                                                label={i18n.t('Closed by')}
                                                data={props.users && props.users[props.maintenance_event.user_closed]?.email}
                                            />
                                            <TextDisplay
                                                label={i18n.t('Observations')}
                                                data={props.rex}
                                                large
                                            />
                                        </CutForm>
                                    </DetailFrame>
                                )
                        }
                    </>
                )
            }
            <DetailFrame
                title={i18n.t('Documents')}
            >
                <RenderDocuments
                    label={i18n.t('Documents')}
                    data={props.maintenance_event.documents}
                    documents_for_event={props.documents_for_event}
                    device={device}
                />
            </DetailFrame>
            <InterventionHistory
                event_id={props.event_id}
                component_id={props.component_id}
                interventionIdRedirect={props.interventionIdRedirect}
                ecm_id={props.asset && props.asset.ecm_id}
            />
        </div>
    );
};

const mapStateToProps = (state, props) => {
    const { event_id } = props;
    const { asset_id } = props;
    const content_type = 'maintenanceevent';
    const is_railnovian_impersonating = (state.users.me != null && state.users.me.actual_user != null ? state.users.me.actual_user.email.endsWith('railnova.eu') : false);
    const maintenance_event = event_id ? state.maintenance_events[event_id] : null;
    const content_options = ['preventive', 'open'];
    const content_options_close = ['preventive', 'close'];
    const due_usage = maintenance_event && maintenance_event.due_usage;
    const rex = maintenance_event && maintenance_event.rex;
    const date_closed = maintenance_event && maintenance_event.date_closed;
    const date_due = maintenance_event && maintenance_event.date_due;
    const date_tolerance = maintenance_event && maintenance_event.date_tolerance;
    const date_limit = maintenance_event && maintenance_event.date_limit;
    const remaining_usage = maintenance_event && maintenance_event.remaining_usage;
    const maintenance_type = maintenance_event && maintenance_event.maintenance_type;
    const asset = asset_id && assetsSelectors.getAssetInfos(state, asset_id);
    const publicFieldConfigSelector = asset && content_type
        && assetsSelectors.makePublicFieldsForAssetWithContentTypeAndOptions(
            content_type, content_options,
        );
    const publicFieldConfigSelectorClose = asset && content_type
        && assetsSelectors.makePublicFieldsForAssetWithContentTypeAndOptions(
            content_type, content_options_close,
        );
    const public_fields = asset_id && asset && publicFieldConfigSelector(state, asset_id);
    const public_fields_close = asset_id && asset && publicFieldConfigSelectorClose(state, asset_id);
    const private_fields = content_type
        && companiesSettingsSelectors.getPrivateFieldsForUserWithContentTypeAndOptions(
            content_type, content_options,
        )(state);
    const private_fields_close = content_type
        && companiesSettingsSelectors.getPrivateFieldsForUserWithContentTypeAndOptions(
            content_type, content_options_close,
        )(state);
    const poi = maintenance_event
        && locationsSelectors.getLocationsById(state, maintenance_event.poi);
    const getMaintenanceSettings = assetsSelectors.makeMaintenanceSettingsForAsset();
    const maintenance_settings = getMaintenanceSettings(state, asset_id);
    const impact_service_status_type = maintenance_settings
        && maintenance_settings.impact_service_status_type;
    const operational_status_type = maintenance_settings
        && maintenance_settings.operational_status_type;
    const impact_service_status = impact_service_status_type
        && maintenance_event
        && maintenance_event.impact_service_status
        && impact_service_status_type[maintenance_event.impact_service_status];
    const impact_status = operational_status_type
        && maintenance_event
        && maintenance_event.impact_status
        && operational_status_type[maintenance_event.impact_status];
    const hotline_called = maintenance_event && maintenance_event.hotline_called ? i18n.t('yes') : i18n.t('no');
    const documents_for_event = event_id
        && documentsSelectors.getDocumentsForEvent(state, event_id);
    const restrictions = restrictionsSelectors.getRestrictionsByEvent(state, event_id);
    const counter = maintenance_event
        && countersSelectors.getCounter(state, maintenance_event.counter);
    const counter_unit = counter && counter.unit;
    const counter_value = counter && counter.value;
    const counter_value_date = counter && counter.value_date;
    const counter_daily_avg = counter && counter.daily_avg;
    const counter_increment_date = counter && counter.increment_date;
    const operations = operationsSelectors.getOperations(state);
    const operation = operations
        && maintenance_event
        && maintenance_event.operation
        && operations[maintenance_event.operation];
    const operation_name = operation && operation.name;
    const event_state = maintenance_event && maintenance_event.state;
    const push_infos = props.push_infos || uxSelectors.getPushInfos(state);
    return {
        maintenance_event: maintenance_event || {},
        is_railnovian_impersonating,
        maintenance_type,
        counter_unit,
        counter_increment_date,
        due_usage,
        date_due,
        rex,
        event_state,
        date_closed,
        operation_name,
        date_tolerance,
        date_limit,
        counter_value,
        counter_value_date,
        counter_daily_avg,
        remaining_usage,
        public_fields,
        public_fields_close,
        private_fields,
        private_fields_close,
        content_options,
        content_options_close,
        push_infos,
        asset: asset || {},
        poi: poi || {},
        impact_service_status: impact_service_status || {},
        impact_status: impact_status || {},
        hotline_called,
        documents_for_event: documents_for_event || {},
        restrictions: restrictions || {},
        users: state.users.all,
    };
};

const mapDispatchToProps = (dispatch) => ({
    fetchEventDetails: (event_id) => dispatch(
        maintenanceEventsOperations.fetchDetails(event_id),
    ),
    discardEvent: (event_id) => dispatch(maintenanceEventsOperations.discardEvent(event_id)),
    fetchLocation: (id) => dispatch(locationsOperations.fetchDetails(id)),
    fetchDocumentsForEvent: (event_id) => (
        dispatch(documentsOperations.fetchForEvent(event_id))
    ),
    setPushInfos: (push_infos, from = null) => dispatch(uxOperations.setPushInfos(push_infos, from)),
});

export default withMultiDeviceLayout(connect(mapStateToProps, mapDispatchToProps)(PreventiveEventDetail));
