import React from 'react';
import PropTypes from 'prop-types';

import './ToggleableOverlay.scss';

class ToggleableOverlay extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            display_overlay: false,
        };

        this.overlay_parent = React.createRef();

        this.toggleDisplay = this.toggleDisplay.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        window.addEventListener('mousedown', this.handleClickOutside);
        window.addEventListener('touchend', this.handleClickOutside);
    }

    componentWillUnmount() {
        window.removeEventListener('mousedown', this.handleClickOutside);
        window.removeEventListener('touchend', this.handleClickOutside);
    }

    toggleDisplay(evt) {
        evt.stopPropagation();

        const display_overlay = !this.state.display_overlay;
        this.setState({ display_overlay });

        if (this.props.onClick) {
            this.props.onClick(display_overlay);
        }
    }

    handleClickOutside(evt) {
        // if the overlay is not shown or not created, no need to handle a clickOutside
        if (!this.state.display_overlay || !this.overlay_parent.current) return;
        if (this.overlay_parent && !this.overlay_parent.current.contains(evt.target)) {
            this.toggleDisplay(evt);
        }
    }

    render() {
        return (
            <div className="overlay-parent" ref={this.overlay_parent}>
                <div className="overlay-toggler" onClick={this.toggleDisplay}>
                    { this.props.toggler }
                </div>
                <div className="overlay-child">
                    { this.state.display_overlay && this.props.children }
                </div>
            </div>
        );
    }
}

ToggleableOverlay.defaultProps = {
    className: '',
};

ToggleableOverlay.propTypes = {
    toggler: PropTypes.element,
    onClick: PropTypes.func,
};

export default ToggleableOverlay;
