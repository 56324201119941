/**
 * Created by lpostula on 28/12/17.
 */
import i18n from 'i18next';
import { createSelector } from 'reselect';
import { getOperationGroups } from '../operation_groups/selectors';
import { getFilteredAssetList, isFilterActive } from '../filtering/selectors';
import { getAssetsInfos } from '../assets/selectors';
import { getComponentStructures } from '../component_structures/selectors';

export const getOperations = (state) => state.operations;

function sort_options(a, b) {
    const sa = a.label.toLowerCase();
    const sb = b.label.toLowerCase();
    return -(sa < sb) || +(sa !== sb);
}

export const getAllOperationsOptions = createSelector(
    [getOperations, getOperationGroups],
    (operations, operation_groups) => {
        const options = [];
        for (const group_id in operation_groups) {
            const group = operation_groups[group_id];
            for (const operation_id of group.operations) {
                const operation = operations[operation_id];
                if (operation) {
                    options.push({
                        value: operation.id,
                        label: `${group.name}: ${operation.name}`,
                    });
                }
            }
        }
        options.sort(sort_options);
        return options;
    },
);

export const getOperationsAndGroupsOptions = createSelector(
    [getOperations, getOperationGroups],
    (operations, operation_groups) => {
        const options = [];

        const groups_options = [];
        for (const group_id in operation_groups) {
            const group = operation_groups[group_id];
            for (const operation_id of group.operations) {
                const operation = operations[operation_id];
                if (operation) {
                    options.push({
                        value: operation.id,
                        label: `${group.name}: ${operation.name}`,
                    });
                }
            }
            groups_options.push({
                value: -group_id,
                label: `${i18n.t('Group')}: ${group.name}`,
            });
        }
        options.sort(sort_options);
        groups_options.sort(sort_options);
        return groups_options.concat(options);
    },
);

export const getOperationsAndGroupsOptionsByAssets = createSelector(
    [
        getOperations,
        getOperationGroups,
        getFilteredAssetList,
        isFilterActive,
        getAssetsInfos,
        getComponentStructures,
    ],
    (
        operations,
        operation_groups,
        filtered_assets,
        filter_active,
        assets,
        component_structures,
    ) => {
        // filtered_assets got the assets that we do not have access now
        // assets don't
        let asset_ids = [];
        if (filter_active) {
            asset_ids = filtered_assets;
        } else {
            asset_ids = _.pluck(assets, 'id');
        }

        // create dictionary of used component structures O(n)
        const assets_mp = {};
        for (const id of asset_ids) {
            const asset = assets[id];
            // asset may not be in assets
            if (asset && asset.component_structure) {
                assets_mp[asset.component_structure] = 1;
            }
        }
        const options = [];

        const groups_options = [];
        for (const id in assets_mp) {
            const mp = component_structures[id];
            if (!mp) continue;
            for (const id_og of mp.operation_groups) {
                const group = operation_groups[id_og];
                for (const id_operation of group.operations) {
                    const operation = operations[id_operation];
                    if (operation) {
                        options.push({
                            value: operation.id,
                            label: `${group.name}: ${operation.name}`,
                        });
                    }
                }
                groups_options.push({
                    value: -id_og,
                    label: `${i18n.t('Group')}: ${group.name}`,
                });
            }
        }
        options.sort(sort_options);
        groups_options.sort(sort_options);
        return _.uniq(groups_options.concat(options), (v) => v.value);
    },
);

export default {
    getOperations,
    getAllOperationsOptions,
    getOperationsAndGroupsOptions,
    getOperationsAndGroupsOptionsByAssets,
};
