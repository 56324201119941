import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';

import './Table.scss';
import NoDataLogo from './img/no-data-logo.svg';
import Button from '../../buttons/button/Button';
import AddItemButton from '../../buttons/addItemButton/AddItemButton';
import Checkbox from '../../buttons/checkbox/Checkbox';
import TablePagination, {
    THEMES as PAGINATION_THEMES,
    DISPLAY_TYPE as PAGINATION_DISPLAY_TYPE,
} from '../tablePagination/TablePagination';
import InlineDropdown, {
    DISPLAY_TYPE as INLINE_DROPDOWN_DISPLAY_TYPE,
} from '../../selectors/inlineDropdown/InlineDropdown';
import { findBreakPoint, useResponsiveSize } from '../../hooks/useResponsiveBreakpoints';
import fn_array_to_dict from '../../../utils/array_of_objects_to_object';
import ButtonGrouper from '../../buttons/buttonGrouper/ButtonGrouper';
import TableSortIndicator, { SORT_OPTIONS } from '../tableSortIndicator/TableSortIndicator';

const DISPLAY_TYPE = {
    MOBILE: 'mobile',
    TABLET: 'tablet',
    DESKTOP: 'desktop',
};

const THEMES = {
    PAGE: 'page-table-theme',
    MODAL: 'modal-table-theme',
    INLINE: 'inline-table-theme',
};

const display_breakpoints = [
    { min: 0, max: 768, classname: DISPLAY_TYPE.MOBILE },
    { min: 768, max: 1280, classname: DISPLAY_TYPE.TABLET },
    { min: 1280, classname: DISPLAY_TYPE.DESKTOP },
];

const initializeColumns = (columns) => (columns.map((v) => ({ ...v, data_key: v.data_key || v.key })));

const initializeTabletArrangement = (ta, columns) => {
    if (ta) return ta;
    return { secondary_longs: columns.map((v) => ({ key: v.key })) };
};

const initializeMobileArrangement = (ta, ma, columns) => {
    if (ma) return ma;
    return initializeTabletArrangement(ta, columns);
};

const constructOrderingInfo = (ordering, columns) => {
    const info = {};
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < columns.length; i++) {
        const element = columns[i];
        const sort_key = element.sort_key || element.key;
        info[sort_key] = null;
    }
    if (ordering) {
        let key = ordering;
        if (ordering.includes('-')) {
            key = ordering.replace('-', '');
            if (info.hasOwnProperty(key)) info[key] = SORT_OPTIONS.DESC;
        } else if (info.hasOwnProperty(key)) info[key] = SORT_OPTIONS.ASC;
    }
    return info;
};

const defaultRenderer = (v) => v;

const Loader = () => (
    <div className="loading-holder">
        <div className="spinner-rolling">
            <div className="spinner">
                <div className="outer" />
                <div className="inner" />
            </div>
        </div>
    </div>
);

const Vector = () => (
    <svg
        width="8"
        height="17"
        viewBox="0 0 8 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M5.45315 11.3144V0.508927C5.45315 0.227872 5.25796 0 5.01721 0H2.98283C2.74208 0 2.54689 0.227872 2.54689 0.508927V11.3144H0.873639C0.0968671 11.3144 -0.292137 12.4108 0.257111 13.052L3.38349 16.7019C3.724 17.0994 4.27604 17.0994 4.61651 16.7019L7.74289 13.052C8.29214 12.4108 7.90313 11.3144 7.12636 11.3144H5.45315Z"
            fill="#C1C8D3"
        />
    </svg>
);

const renderNoData = (props) => (
    <div className={`no-data-container ${props.smallPlaceholder ? 'small' : ''}`}>
        <img
            className="not-data-image"
            src={NoDataLogo}
            draggable={false}
            alt="no data found"
        />
        <div className="main-message">{i18n.t('No data found')}</div>
        <div className="sub-message">
            {i18n.t('Look around, search for something else')}
        </div>
    </div>
);

const DesktopTable = (props) => {
    const [_orderingInfo, setOrderingInfo] = useState(
        constructOrderingInfo(props.ordering, props.columns),
    );
    const has_data = props.data.length > 0;
    const display_pre = ((!has_data && props.actions)
        || (has_data && (props.title || props.bulk_actions || props.actions)));
    useEffect(
        () => setOrderingInfo(constructOrderingInfo(props.ordering, props.columns)),
        [props.ordering, props.columns],
    );

    const setOrdering = (v, key, sortable = true) => {
        if (!sortable || !props.setOrdering) return;
        if (v === SORT_OPTIONS.ASC) {
            props.setOrdering(key);
        } else {
            props.setOrdering(`-${key}`);
        }
    };

    const renderValue = (data, column_config) => {
        const renderer = column_config.renderer || defaultRenderer;
        return renderer(
            column_config && data[column_config.data_key],
            data,
            props.display_type,
            props.setExpandedRow,
        );
    };

    const renderRow = (data, index) => {
        const rowIsSelected = props.isRowSelected(data);
        const isExpanded = props.expandedRow
            && data[props.data_unique_id] === props.expandedRow[props.data_unique_id];
        const isRowValid = props.isRowValid && props.isRowValid(data);
        return (
            <React.Fragment key={index}>
                <tr
                    className={`tr-table${rowIsSelected ? ' selected' : ''}${isExpanded ? ' expanded' : ''}${isRowValid === true ? ' valid' : ((isRowValid === false ? ' notValid' : ''))}`}
                    key={index}
                    onClick={(e) => (props.onRowClick ? props.onRowClick(data, index, e) : null)}
                >
                    {
                        _.map(props.columns, (d, col_index) => (
                            <td
                                key={col_index}
                                className="td-table"
                            >
                                {renderValue(data, d)}
                            </td>
                        ))
                    }
                    {/* check that we have proper bulk action defined */}
                    {props.validBulkActions
                    && (
                        <td
                            className="action td-table"
                            // onClick={(a) => props.onClick(a)}
                            key={index}
                        >
                            <Checkbox
                                value={rowIsSelected}
                                onChange={(v) => props.handleBulkSelect(
                                    v, data[props.data_unique_id],
                                )}
                            />
                        </td>
                    )}
                </tr>
                {
                    isExpanded
                        && (
                            <tr className="tr-table expanded-row">
                                <td
                                    className="td-table"
                                    colSpan="100%"
                                >
                                    {
                                        props.expandRowRenderer(
                                            data[props.data_unique_id],
                                            props.expandedRow,
                                            props.display_type,
                                            props.setExpandedRow,
                                        )
                                    }
                                </td>
                            </tr>
                        )
                }
            </React.Fragment>
        );
    };

    return (
        <>
            {display_pre && !_.isEmpty(display_pre)
                ? (
                    <div className={`table-pre ${props.anyBulkSelect ? 'bulk-select-on' : ''}`}>
                        <div className={`table-title${(props.bulk_actions && props.bulk_actions.selectedMessage) ? ' custom' : ''}`}>{(props.bulk_actions && props.bulk_actions.selectedMessage) || props.title}</div>
                        {has_data && !props.isInline && !props.noPagination
                    && (
                        <div className="table-result-nav">
                            {props.numberOfResults
                        && (
                            <div className="result-count">
                                {props.numberOfResults}
                                {' '}
                                Result(s)
                            </div>
                        )}
                            {props.pagination && !props.noPagination
                        && (
                            <>
                                <TablePagination
                                    {...props.pagination}
                                    theme={
                                        props.anyBulkSelect ? PAGINATION_THEMES.DARK
                                            : PAGINATION_THEMES.LIGHT
                                    }
                                />
                                <div className="vertical-separator" />
                                <InlineDropdown
                                    value={props.pagination.page_size}
                                    options={[50, 100, 200, 500]}
                                    labelizer={(v) => v}
                                    onChange={props.pagination.changePageSize}
                                />
                            </>
                        )}
                        </div>
                    )}
                        <div className="table-all-actions">
                            {
                                !props.anyBulkSelect && props.actions
                            && (
                                <div className="table-actions">
                                    {
                                        props.actions && props.actions.map((action) => {
                                            if (action.hasPerm === false) return null;
                                            return (
                                                action.type !== 'add' ? (
                                                    <Button
                                                        primary={action.primary !== false}
                                                        {...action}
                                                        key={action.label}
                                                    />
                                                ) : (
                                                    <AddItemButton
                                                        primary={action.primary !== false}
                                                        {...action}
                                                        key={action.label}
                                                    />
                                                )
                                            );
                                        })
                                    }
                                </div>
                            )
                            }
                            {props.bulk_actions && !props.anyBulkSelect && has_data
                        && (
                            <div className="table-bulk-actions">
                                <div className="table-bulk-actions-title">
                                    {props.bulk_actions.actions.map((action) => action.label)
                                        .join(' / ')}
                                </div>
                                <div className="table-bulk-action-sub">
                                    {props.bulk_actions.subtitle}
                                </div>
                            </div>
                        )}
                            {props.bulk_actions && props.anyBulkSelect && has_data
                        && (
                            <div className="table-bulk-actions">
                                {
                                    props.bulk_actions.actions.map((action) => (
                                        action.type !== 'add' ? (
                                            <Button
                                                primary
                                                {...action}
                                                key={action.label}
                                            />
                                        ) : (
                                            <AddItemButton
                                                primary
                                                {...action}
                                                key={action.label}
                                            />
                                        )
                                    ))
                                }
                                <div className="table-bulk-count">
                                    <span className="count-text">
                                        {
                                            props.bulkSelectAll ? props.data.length
                                                : props.countBulkSelect
                                        }
                                    </span>
                                </div>
                            </div>
                        )}
                        </div>
                    </div>
                )
                : <div className="table-no-pre" />}
            {props.data.length > 0
            && (
                <div className={`table-container ${props.anyBulkSelect ? 'bulk-select-on' : ''}`}>
                    <table>
                        {
                            !props.noHeader
                            /* eslint-disable */
                            && <thead>
                                <tr className="tr-table">
                                    {
                                        _.map(props.columns, (column, index) => {
                                            const sort_key = column.sort_key || column.key;
                                            return (
                                                <th key={index} className={`${display_pre && !_.isEmpty(display_pre) ? '' : 'no-pre'}`}>
                                                    <div
                                                        className={`column-hedear${column.important ? ' important' : ''}`}
                                                        onClick={() => setOrdering(
                                                            !(_orderingInfo[sort_key] === SORT_OPTIONS.ASC),
                                                            sort_key,
                                                            column.sortable,
                                                        )}
                                                    >
                                                        <span>{column.label}</span>
                                                        {
                                                            column.sortable !== false && !props.isInline &&
                                                                <TableSortIndicator
                                                                    option={_orderingInfo[sort_key]}
                                                                />
                                                        }
                                                    </div>
                                                </th>
                                            )
                                        })
                                    }
                                    {props.validBulkActions &&
                                    <th className={`${display_pre && !_.isEmpty(display_pre) ? '' : 'no-pre'}`}>
                                        <Checkbox
                                            value={props.bulkSelectAll}
                                            onChange={props.handleBulkSelectAll}
                                            disabled={props.bulk_actions.select_all_disabled}
                                        />
                                    </th>
                                    }
                                </tr>
                            </thead>
                            /* eslint-enable */
                        }
                        <tbody>
                            {
                                _.map(props.data, (data, index) => {
                                    if (props.limit && index >= props.limit) return null;
                                    return (
                                        renderRow(data, index)
                                    );
                                })
                            }
                        </tbody>
                    </table>
                </div>
            )}
            {props.data.length === 0 && renderNoData(props)}
        </>
    );
};

const TabletTable = (props) => {
    const has_data = props.data.length > 0;
    const display_pre = ((!has_data && props.actions)
        || (has_data && (props.title || props.bulk_actions || props.actions)));
    const display_post = has_data;
    const display_top_line = (
        !!props.mobile_arrangement.primary_shorts || props.validBulkActions
        || !!props.mobile_arrangement.secondary_shorts
    );
    const columns_as_array = fn_array_to_dict(props.columns, 'key');

    const renderValue = (data, mobile_config) => {
        const column_config = columns_as_array[mobile_config.key];
        const renderer = (mobile_config && mobile_config.renderer) || (column_config && column_config.renderer) || defaultRenderer;
        return renderer(
            column_config && data[column_config.data_key],
            data,
            props.display_type,
            props.setExpandedRow,
        );
    };
    // eslint-disable-next-line no-unused-vars
    const renderRow = (data, index) => {
        const rowIsSelected = props.isRowSelected(data);
        const isExpanded = props.expandedRow
            && data[props.data_unique_id] === props.expandedRow[props.data_unique_id];
        return (
            <div
                className="table-tablet-card"
                key={data[props.data_unique_id] || index}
                onClick={(e) => (props.onRowClick ? props.onRowClick(data, index, e) : null)}
            >
                {display_top_line
                && (
                    <div className={`top-line ${
                    (!props.mobile_arrangement.primary_shorts
                        && !props.mobile_arrangement.secondary_shorts) ? 'check-box-alone' : ''
                }`}
                    >
                        {!!props.mobile_arrangement.primary_shorts
                    && props.mobile_arrangement.primary_shorts.length > 0
                    && (
                        <div className="primary-shorts">
                            {
                                props.mobile_arrangement.primary_shorts.map((ps) => (
                                    <div className="primary-short-elm" key={`ps-${ps.key}`}>
                                        {renderValue(data, ps)}
                                    </div>
                                ))
                            }
                        </div>
                    )}
                        {((!!props.mobile_arrangement.secondary_shorts
                        && props.mobile_arrangement.secondary_shorts.length > 0)
                        || props.validBulkActions)
                        && (
                            <div className="secondary-shorts">
                                {
                                    props.mobile_arrangement.secondary_shorts
                                && props.mobile_arrangement.secondary_shorts.map(
                                    (ss, ss_index) => (
                                        <React.Fragment key={`ss-${ss.key}`}>
                                            {ss_index !== 0
                                            && <div className="vertical-separator" />}
                                            <div className="secondary-short-elm">
                                                {renderValue(data, ss)}
                                            </div>
                                        </React.Fragment>
                                    ),
                                )
                                }
                                {props.validBulkActions
                            && (
                                <Checkbox
                                    value={rowIsSelected}
                                    onChange={(v) => props.handleBulkSelect(
                                        v, data[props.data_unique_id],
                                    )}
                                />
                            )}
                            </div>
                        )}
                    </div>
                )}
                <div className="content">
                    <div className="longs">
                        {!!props.mobile_arrangement.primary_long
                        && (
                            <div className="primary-long">
                                {renderValue(data, props.mobile_arrangement.primary_long)}
                            </div>
                        )}
                        <div className="secondary-longs">
                            {
                                props.mobile_arrangement.secondary_longs
                                && props.mobile_arrangement.secondary_longs.map((sl, sl_index) => (
                                    <React.Fragment key={`sl-${sl.key}`}>
                                        {(sl_index !== 0 || !!props.mobile_arrangement.primary_long)
                                        && <div className="horizontal-separator" />}
                                        <div className="secondary-long-elm">
                                            <div className="sle-label">
                                                {columns_as_array[sl.key] && columns_as_array[sl.key].label}
                                            </div>
                                            <div className="sle-value">
                                                {renderValue(data, sl)}
                                            </div>
                                        </div>
                                    </React.Fragment>
                                ))
                            }
                        </div>
                    </div>
                    {props.mobile_arrangement.actions
                    && props.mobile_arrangement.actions.length > 0
                    && (
                        <div className="actions">
                            {
                                props.mobile_arrangement.actions.map((a) => (
                                    <div className="action" key={`a-${a.key || a.id}`}>
                                        {renderValue(data, a)}
                                    </div>
                                ))
                            }
                        </div>
                    )}
                    {
                        isExpanded
                            && (
                                <div className="expanded-row">
                                    {props.expandRowRenderer(
                                        data[props.data_unique_id],
                                        props.expandedRow,
                                        props.display_type,
                                        props.setExpandedRow,
                                    )}
                                </div>
                            )
                    }
                </div>
            </div>
        );
    };
    return (
        <>
            {display_pre && !_.isEmpty(display_pre)
                ? (
                    <div className="table-pre-wrapper">
                        {props.anyBulkSelect && <div className="table-title">{(props.bulk_actions && props.bulk_actions.selectedMessage) || props.title}</div>}
                        <div className={`table-pre ${props.anyBulkSelect ? 'bulk-select-on' : ''}`}>
                            {!props.anyBulkSelect && <div className="table-title">{props.title}</div>}
                            <div className="table-all-actions">
                                {
                                    !props.anyBulkSelect
                                && (
                                    <div className="table-actions">
                                        {
                                            props.actions && props.actions.map((action) => {
                                                if (action.hasPerm === false) return null;
                                                return (
                                                    action.type !== 'add' ? (
                                                        <Button
                                                            primary
                                                            {...action}
                                                            key={action.label}
                                                        />
                                                    ) : (
                                                        <AddItemButton
                                                            primary
                                                            {...action}
                                                            key={action.label}
                                                        />
                                                    )
                                                );
                                            })
                                        }
                                    </div>
                                )
                                }
                                {props.bulk_actions && !props.anyBulkSelect && has_data
                            && (
                                <div className="table-bulk-actions">
                                    <div className="table-bulk-action-titles">
                                        <div className="table-bulk-actions-title">
                                            {props.bulk_actions.actions.map((action) => action.label)
                                                .join(' / ')}
                                        </div>
                                        <div className="table-bulk-action-sub">
                                            {props.bulk_actions.subtitle}
                                        </div>
                                    </div>
                                    <Vector />
                                </div>
                            )}
                                {props.bulk_actions && props.anyBulkSelect && has_data
                            && (
                                <div className="table-bulk-actions">
                                    {
                                        props.bulk_actions.actions.map((action) => (
                                            action.type !== 'add' ? (
                                                <Button
                                                    primary
                                                    {...action}
                                                    key={action.label}
                                                />
                                            ) : (
                                                <AddItemButton
                                                    primary
                                                    {...action}
                                                    key={action.label}
                                                />
                                            )
                                        ))
                                    }
                                    <div className="table-bulk-count">
                                        <span className="count-text">
                                            {
                                                props.bulkSelectAll ? props.data.length
                                                    : props.countBulkSelect
                                            }
                                        </span>
                                    </div>
                                    <Checkbox
                                        value={props.bulkSelectAll}
                                        onChange={props.handleBulkSelectAll}
                                        disabled={props.bulk_actions.select_all_disabled}
                                    />
                                </div>
                            )}
                            </div>
                        </div>
                    </div>
                )
                : <div className="table-no-pre" />}
            {has_data
            && (
                <div className="table-container">
                    {
                        _.map(props.data, (data, index) => (
                            renderRow(data, index)
                        ))
                    }

                </div>
            )}
            {!has_data
            && renderNoData(props)}
            {display_post
            && (
                <div className="table-post">
                    <div className="table-post-left">
                        {props.numberOfResults
                    && (
                        <div className="result-count">
                            {props.numberOfResults}
                            {' '}
                            Result(s)
                        </div>
                    )}
                        {props.pagination
                    && (
                        <InlineDropdown
                            value={props.pagination.page_size}
                            options={[50, 100, 200, 500]}
                            labelizer={(v) => v}
                            onChange={props.pagination.changePageSize}
                            display={INLINE_DROPDOWN_DISPLAY_TYPE.TABLET_AND_MOBILE}
                        />
                    )}
                    </div>
                    {props.pagination
                && (
                    <TablePagination
                        {...props.pagination}
                        theme={props.anyBulkSelect ? PAGINATION_THEMES.DARK : PAGINATION_THEMES.LIGHT}
                        display={PAGINATION_DISPLAY_TYPE.TABLET_AND_MOBILE}
                    />
                )}
                </div>
            )}
        </>
    );
};

const MobileTable = (props) => {
    const has_data = props.data.length > 0;
    const display_pre = (props.actions || props.title || props.bulk_actions);
    const display_post = (props.data.length > 0 && props.pagination);
    const columns_as_array = fn_array_to_dict(props.columns, 'key');

    const renderValue = (data, mobile_config) => {
        const column_config = columns_as_array[mobile_config.key];
        const renderer = (mobile_config && mobile_config.renderer) || (column_config && column_config.renderer) || defaultRenderer;
        return renderer(
            column_config && data[column_config.data_key],
            data,
            props.display_type,
            props.setExpandedRow,
        );
    };

    // eslint-disable-next-line no-unused-vars
    const renderRow = (data, index) => {
        const rowIsSelected = props.isRowSelected(data);
        const isExpanded = props.expandedRow
            && data[props.data_unique_id] === props.expandedRow[props.data_unique_id];
        return (
            <div
                className="table-tablet-card"
                key={data[props.data_unique_id]}
                onClick={(e) => (props.onRowClick ? props.onRowClick(data, index, e) : null)}
            >
                {(!!props.mobile_arrangement.primary_shorts || props.validBulkActions)
                && (
                    <div className={
                    `top-line ${!props.mobile_arrangement.primary_shorts ? 'check-box-alone' : ''}`
                    }
                    >
                        {!!props.mobile_arrangement.primary_shorts
                    && props.mobile_arrangement.primary_shorts.length > 0
                    && (
                        <div className="primary-shorts">
                            {
                                props.mobile_arrangement.primary_shorts.map((ps) => (
                                    <div className="primary-short-elm" key={ps.key}>
                                        {renderValue(data, ps)}
                                    </div>
                                ))
                            }
                        </div>
                    )}
                        {props.validBulkActions
                    && (
                        <Checkbox
                            value={rowIsSelected}
                            onChange={(v) => props.handleBulkSelect(
                                v, data[props.data_unique_id],
                            )}
                        />
                    )}
                    </div>
                )}
                <div className="content">
                    <div className="longs">
                        {!!props.mobile_arrangement.primary_long
                        && (
                            <div className="primary-long">
                                {renderValue(data, props.mobile_arrangement.primary_long)}
                            </div>
                        )}
                        {!!props.mobile_arrangement.secondary_longs
                        && props.mobile_arrangement.secondary_longs.length > 0
                        && (
                            <div className="secondary-longs">
                                {
                                    props.mobile_arrangement.secondary_longs.map((sl, sl_index) => (
                                        <React.Fragment key={`sl-${sl.key}`}>
                                            {(sl_index !== 0 || !!props.mobile_arrangement.primary_long)
                                        && <div className="horizontal-separator" />}
                                            <div className="secondary-long-elm">
                                                <div className="sle-label">
                                                    {columns_as_array[sl.key] && columns_as_array[sl.key].label}
                                                </div>
                                                <div className="sle-value">
                                                    {renderValue(data, sl)}
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    ))
                                }
                            </div>
                        )}
                    </div>
                    {!!props.mobile_arrangement.secondary_shorts
                    && props.mobile_arrangement.secondary_shorts.length > 0 && (
                        !!props.mobile_arrangement.primary_long
                        || props.mobile_arrangement.secondary_longs.length > 0
                    )
                    && <div className="horizontal-separator" />}
                    {!!props.mobile_arrangement.secondary_shorts
                    && props.mobile_arrangement.secondary_shorts.length > 0
                    && (
                        <div className="secondary-shorts">
                            {
                                props.mobile_arrangement.secondary_shorts.map(
                                    (ss, ss_index) => (
                                        <React.Fragment key={`ss-${ss.key}`}>
                                            {ss_index !== 0
                                        && <div className="vertical-separator" />}
                                            <div className="secondary-short-elm">
                                                {renderValue(data, ss)}
                                            </div>
                                        </React.Fragment>
                                    ),
                                )
                            }
                        </div>
                    )}
                </div>
                {!!props.mobile_arrangement.actions
                && props.mobile_arrangement.actions.length > 0
                    && (
                        <>
                            <div className="horizontal-separator" />
                            <div className="actions">
                                {
                                    props.mobile_arrangement.actions.map((a) => (
                                        <div className="action" key={`a-${a.key || a.id}`}>{renderValue(data, a)}</div>
                                    ))
                                }
                            </div>
                        </>
                    )}
                {
                    isExpanded
                        && (
                            <div className="expanded-row">
                                {props.expandRowRenderer(
                                    data[props.data_unique_id],
                                    props.expandedRow,
                                    props.display_type,
                                    props.setExpandedRow,
                                )}
                            </div>
                        )
                }
            </div>
        );
    };

    const config = [];
    if (props.actions) {
        for (const action of props.actions) {
            if (action.hasPerm) {
                config.push(action);
            }
        }
    }
    return (
        <>
            {display_pre && !_.isEmpty(display_pre)
                ? (
                    <div className="table-pre-wrapper">
                        <div className="table-pre-top">
                            <div className="table-title">{(props.bulk_actions && props.bulk_actions.selectedMessage) || props.title}</div>
                            {
                                !props.anyBulkSelect && !!props.actions
                            && <ButtonGrouper config={config} className="table-actions" />
                            }
                        </div>
                        {has_data
                    && (
                        <div className={`table-pre ${props.anyBulkSelect ? 'bulk-select-on' : ''}`}>
                            <div className="table-all-actions">
                                {props.bulk_actions && !props.anyBulkSelect
                            && (
                                <div className="table-bulk-actions">
                                    <div className="table-bulk-action-titles">
                                        <div className="table-bulk-actions-title">
                                            {props.bulk_actions.actions.map((action) => action.label)
                                                .join(' / ')}
                                        </div>
                                        <div className="table-bulk-action-sub">
                                            {props.bulk_actions.subtitle}
                                        </div>
                                    </div>
                                    <Vector />
                                </div>
                            )}
                                {props.bulk_actions && props.anyBulkSelect
                            && (
                                <div className="table-bulk-actions">
                                    {
                                        props.bulk_actions.actions.map((action) => (
                                            action.type !== 'add' ? (
                                                <Button
                                                    primary
                                                    {...action}
                                                />
                                            ) : (
                                                <AddItemButton
                                                    primary
                                                    {...action}
                                                />
                                            )
                                        ))
                                    }
                                    <div className="table-bulk-count">
                                        <span className="count-text">
                                            {
                                                props.bulkSelectAll ? props.data.length
                                                    : props.countBulkSelect
                                            }
                                        </span>
                                    </div>
                                    <Checkbox
                                        value={props.bulkSelectAll}
                                        onChange={props.handleBulkSelectAll}
                                        disabled={props.bulk_actions.select_all_disabled}
                                    />
                                </div>
                            )}
                            </div>
                        </div>
                    )}
                    </div>
                )
                : <div className="table-no-pre" />}
            {has_data
            && (
                <div className="table-container">
                    {
                        _.map(props.data, (data, index) => (
                            renderRow(data, index)
                        ))
                    }

                </div>
            )}
            {!has_data && renderNoData(props)}
            {display_post
            && (
                <div className="table-post">
                    <div className="table-post-left">
                        {props.numberOfResults
                    && (
                        <div className="result-count">
                            {props.numberOfResults}
                            {' '}
                            Result(s)
                        </div>
                    )}
                        {props.pagination
                    && (
                        <InlineDropdown
                            value={props.pagination.page_size}
                            options={[50, 100, 200, 500]}
                            labelizer={(v) => v}
                            onChange={props.pagination.changePageSize}
                            display={INLINE_DROPDOWN_DISPLAY_TYPE.TABLET_AND_MOBILE}
                        />
                    )}
                    </div>
                    {props.pagination
                && (
                    <TablePagination
                        {...props.pagination}
                        theme={props.anyBulkSelect ? PAGINATION_THEMES.DARK : PAGINATION_THEMES.LIGHT}
                        display={PAGINATION_DISPLAY_TYPE.TABLET_AND_MOBILE}
                    />
                )}
                </div>
            )}
        </>
    );
};

const Table = (props) => {
    const [_bulkSelect, setBulkSelect] = useState([]);
    const [_prevBulkSelect, setPrevBulkSelect] = useState(null);
    const [_bulkSelectAll, setBulkSelectAll] = useState(false);
    const [_numberOfResults, setNumberOfResults] = useState(null);
    const [_displayType, setDisplayType] = useState(DISPLAY_TYPE.DESKTOP);
    const [_columns, setColumns] = useState(initializeColumns(props.columns));
    const [_tabletArrangement, setTabletArrangement] = useState(
        initializeTabletArrangement(props.tablet_arrangement, props.columns),
    );
    const [_mobileArrangement, setMobileArrangement] = useState(
        initializeMobileArrangement(
            props.tablet_arrangement,
            props.mobile_arrangement,
            props.columns,
        ),
    );
    const [_isInline, setIsInline] = useState(false);
    const [_expandedRow, setExpandedRow] = useState(null);

    const validBulkActions = (props.bulk_actions && props.bulk_actions.actions);
    const anyBulkSelect = _bulkSelect.length > 0;

    const table_sizer_element = useRef(null);
    const { current_width } = useResponsiveSize(table_sizer_element);

    const handleBulkSelect = (selected, index) => {
        if (selected) {
            setBulkSelect([..._bulkSelect, index]);
        } else {
            setBulkSelect(_bulkSelect.filter((i) => i !== index));
        }
        setBulkSelectAll(false);
    };

    const handleBulkSelectAll = (selected) => {
        if (props.bulk_actions.select_all_disabled) return;
        if (selected) {
            setBulkSelect(props.data.map((v) => v[props.data_unique_id]));
            setBulkSelectAll(true);
        } else {
            setBulkSelect([]);
            setBulkSelectAll(false);
        }
    };

    const isRowSelected = (data) => !!props.bulk_actions
        && _bulkSelect.includes(data[props.data_unique_id]);

    // remove selected
    useEffect(() => {
        if (validBulkActions && props.data && props.data.length > 0) {
            const updatedBulkSelect = [];
            // eslint-disable-next-line no-plusplus
            for (let j = 0; j < props.data.length; j++) {
                const key = props.data[j][props.data_unique_id];
                if (_bulkSelect.includes(key)) {
                    updatedBulkSelect.push(key);
                }
            }
            setBulkSelect(updatedBulkSelect);
        }
        if (props.data) {
            setNumberOfResults(
                (!props.pagination || props.pagination.total_page <= 1) ? props.data.length
                    : `${props.pagination.total_page * props.pagination.page_size}+`,
            );
        }
    }, [props.data]);

    useEffect(() => {
        if (props.bulkSelected !== undefined) {
            setBulkSelect(props.bulkSelected || []);
        }
    }, [props.bulkSelected]);

    useEffect(() => {
        if (!_.isEqual(_prevBulkSelect, _bulkSelect)) {
            if (props.onSelect) {
                props.onSelect(_bulkSelect);
            }
            setPrevBulkSelect(_bulkSelect);
        }
    }, [_bulkSelect]);

    useEffect(() => {
        setColumns(initializeColumns(props.columns));
    }, [props.columns]);

    useEffect(() => {
        setTabletArrangement(initializeTabletArrangement(props.tablet_arrangement, props.columns));
        if (!props.mobile_arrangement) {
            initializeMobileArrangement(
                props.tablet_arrangement,
                props.mobile_arrangement,
                props.columns,
            );
        }
    }, [props.tablet_arrangement]);

    useEffect(() => {
        setMobileArrangement(initializeMobileArrangement(
            props.tablet_arrangement,
            props.mobile_arrangement,
            props.columns,
        ));
    }, [props.mobile_arrangement]);

    useEffect(
        () => {
            if (props.deviceOverride) setDisplayType(props.deviceOverride);
            else {
                let displayType = findBreakPoint(display_breakpoints, current_width) || DISPLAY_TYPE.DESKTOP;
                if (displayType && props.noTabletDisplay && displayType === DISPLAY_TYPE.TABLET) {
                    displayType = DISPLAY_TYPE.DESKTOP;
                }
                if (displayType && props.noMobileDisplay && displayType === DISPLAY_TYPE.MOBILE) {
                    displayType = DISPLAY_TYPE.DESKTOP;
                }
                setDisplayType(displayType);
            }
        },
        [current_width],
    );

    useEffect(() => {
        if (props.theme && props.theme === THEMES.INLINE) {
            setIsInline(true);
        } else {
            setIsInline(false);
        }
    }, [props.theme]);

    return (
        <div
            ref={table_sizer_element}
            className={`table-layout-container ${_displayType} ${props.theme}`}
        >
            {_displayType === DISPLAY_TYPE.DESKTOP
            && (
                <DesktopTable
                    {...props}
                    columns={_columns}
                    handleBulkSelectAll={handleBulkSelectAll}
                    handleBulkSelect={handleBulkSelect}
                    numberOfResults={_numberOfResults}
                    validBulkActions={validBulkActions}
                    anyBulkSelect={anyBulkSelect}
                    isRowSelected={isRowSelected}
                    bulkSelectAll={_bulkSelectAll}
                    countBulkSelect={_bulkSelect.length}
                    display_type={_displayType}
                    isInline={_isInline}
                    setExpandedRow={setExpandedRow}
                    expandedRow={_expandedRow}
                />
            )}
            {_displayType === DISPLAY_TYPE.TABLET
            && (
                <TabletTable
                    {...props}
                    columns={_columns}
                    handleBulkSelectAll={handleBulkSelectAll}
                    handleBulkSelect={handleBulkSelect}
                    numberOfResults={_numberOfResults}
                    validBulkActions={validBulkActions}
                    anyBulkSelect={anyBulkSelect}
                    isRowSelected={isRowSelected}
                    bulkSelectAll={_bulkSelectAll}
                    countBulkSelect={_bulkSelect.length}
                    mobile_arrangement={_tabletArrangement}
                    display_type={_displayType}
                    isInline={_isInline}
                    setExpandedRow={setExpandedRow}
                    expandedRow={_expandedRow}
                />
            )}
            {_displayType === DISPLAY_TYPE.MOBILE
            && (
                <MobileTable
                    {...props}
                    columns={_columns}
                    handleBulkSelectAll={handleBulkSelectAll}
                    handleBulkSelect={handleBulkSelect}
                    numberOfResults={_numberOfResults}
                    validBulkActions={validBulkActions}
                    anyBulkSelect={anyBulkSelect}
                    isRowSelected={isRowSelected}
                    bulkSelectAll={_bulkSelectAll}
                    countBulkSelect={_bulkSelect.length}
                    mobile_arrangement={_mobileArrangement}
                    display_type={_displayType}
                    isInline={_isInline}
                    setExpandedRow={setExpandedRow}
                    expandedRow={_expandedRow}
                />
            )}
            {props.is_fetching
            && (
                <div className="loading-overlay">
                    <div className="loader-block">
                        <Loader />
                        <span>{props.loadingText || 'LOADING'}</span>
                    </div>
                </div>
            )}
        </div>
    );
};

Table.defaultProps = {
    data: [],
    columns: null,
    is_fetching: false,
    actions: null,
    bulk_actions: null, // { unique_id: 'data_key', actions: []}
    title: null,
    mobile_arrangement: null,
    theme: THEMES.PAGE,
    noTabletDisplay: false,
    noMobileDisplay: false,
    expandRowRenderer: null,
    bulkSelected: undefined,
};

Table.propTypes = {
    data: PropTypes.array,
    columns: PropTypes.array,
    panel_title: PropTypes.func,
    onRowClick: PropTypes.func,
    action_text: PropTypes.string,
    is_fetching: PropTypes.bool,
    mobile_arrangement: PropTypes.object,
    setOrdering: PropTypes.func,
    ordering: PropTypes.string,
    theme: PropTypes.oneOf(Object.values(THEMES)),
    noTabletDisplay: PropTypes.bool,
    noMobileDisplay: PropTypes.bool,
    noHeader: PropTypes.bool,
    onSelect: PropTypes.func,
    expandRowRenderer: PropTypes.func,
};

export default Table;
export { THEMES };
