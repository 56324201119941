import React, { useState, useEffect } from 'react';
import components from 'components';
import i18n from 'i18next';
import moment from 'moment-timezone';
import { connect } from 'react-redux';

import { assetsSelectors } from 'railfleet_state/ducks/assets';
import { uxSelectors, uxOperations } from 'railfleet_state/ducks/ux';
import { usersSelectors } from 'railfleet_state/ducks/users';
import { maintenanceEventsSelectors, maintenanceEventsOperations } from 'railfleet_state/ducks/maintenance_events';
import { restrictionsSelectors, restrictionsOperations } from 'railfleet_state/ducks/restrictions';
import {
    assetOperationalStatusOperations,
    assetOperationalStatusSelectors,
} from 'railfleet_state/ducks/asset_operational_status';
import {
    filteringCollectionsOperations,
    filteringCollectionsSelectors,
} from 'railfleet_state/ducks/filtering_collections';
import './DriverLogbook.scss';
import HomologationList from 'compounds/lists/homologationList/HomologationList';
import noAssetSelectedImg from './no_asset_selected.svg';
import EventList from '../../lists/eventsList/EventsList';
import RestrictionCardList from '../../lists/restrictionCardList/RestrictionCardList';
import EventHub from '../../../../../dashboard/static/event/event_hub';
import ConfirmationDialog from '../../../components/containers/ConfirmationDialog/ConfirmationDialog';
import LocoPreparation from '../locoPreparation/LocoPreparation';
import { LAYOUT } from '../../../components/app/MultiDeviceLayout';
import MODAL_CONSTANTS from '../../../components/containers/Modals/Constants';
import Modal from '../../../components/containers/Modals/Modal';
import useArrayToDict from '../../../components/hooks/useArrayToDict';
import useFetch from '../../../components/hooks/useFetch';
import Toast from '../../../components/frames/toast/Toast';
import PreparationCard from '../../../components/display/preparationCard/PreparationCard';
import withNavigate from '../../../components/app/withNavigate';
import AssetTrackOnMap from '../../../components/display/assetTrackOnMap/AssetTrackOnMap';
import DocumentsList from '../../lists/documentsList/DocumentsList';
import HeaderModal from '../../../components/headers/headerModal/HeaderModal';
import OperationalStatusForm from '../../forms/operationalStatusForm/OperationalStatusForm';

const { MultiDeviceLayout } = components.app;
const { CallHotlineButton, AddItemButton } = components.buttons;
const { SelectFilter } = components.filters;
const { Loader } = components.animations;
const { usePerm } = components.hooks;

const Homologations = (props) => (
    <div className="board homologations">
        <div>
            <div className="board-title">{i18n.t('Aptitudes')}</div>
            <HomologationList
                asset_id={props.asset_id}
                textNone={i18n.t('No homologations')}
                withFlags
                driver_logbook
            />
        </div>
    </div>
);

const DriverLogbookComponent = (props) => {
    const [_assetId, setAssetId] = useState(null);
    const [_loading, setLoading] = useState(false);
    const [_eventFormVisible, setEventFormVisible] = useState(false);
    const [_preparationTypeSelected, setPreparationTypeSelected] = useState(false);
    const [_locoPreparationSubmitted, setLocoPreparationSubmitted] = useState(false);
    const [_selectedEvent, setSelectedEvent] = useState(null);
    const [_hotlineVisible, setHotlineVisible] = useState(false);
    const hasPerm = usePerm(_assetId);
    const [_newEvent, setNewEvent] = useState(null);
    const [_latestPreparations, setLatestPreparations] = useState(null);
    const [_preparationTypes, setPreparationTypes] = useState(null);
    const [_maintenanceType, setMaintenanteType] = useState(null);
    const [_initialCustomFields, setInitialCustomFields] = useState(null);
    const latest_preparations = useArrayToDict(_latestPreparations, 'type');
    const [_statusForm, setStatusForm] = useState(null);
    const { fetchAll } = useFetch();
    const hasPermToViewMap = hasPerm('view_gps_realtime_data');

    const fetchLatest = () => {
        fetchAll('/api/preparation/latest_preparations/', {
            asset_id: props.asset_id,
        }).then((d) => {
            if (d && !d.error) {
                setLatestPreparations(d);
            } else {
                setLatestPreparations(null);
            }
        });
    };

    const fetchPreparationTypes = () => {
        fetchAll('/api/preparation_type/types_for_asset/', {
            asset_id: props.asset_id,
        }).then((d) => {
            if (d && !d.error) {
                setPreparationTypes(d);
            } else {
                setPreparationTypes(null);
            }
        });
    };

    useEffect(() => {
        if (props.asset_id) {
            fetchPreparationTypes();
            fetchLatest();
        }
    }, [props.asset_id]);

    useEffect(() => {
        if (_assetId) {
            setLoading(true);
            props.fetchStatusForAsset(_assetId).then(() => props.fetchMaintenanceEventsForAsset(_assetId).then(() => props.fetchRestrictionsForAsset(_assetId).then(() => {
                setLoading(false);
            })));
        }
    }, [_assetId]);

    useEffect(() => {
        if (props.location.query && !_.isEmpty(props.location.query)) {
            const initialCustomFields = {};
            for (const key in props.location.query) {
                const value = props.location.query[key];
                if (key.includes('initial')) {
                    const newKey = key.split('initial_')[1];
                    initialCustomFields[newKey] = value;
                }
            }
            setInitialCustomFields(initialCustomFields);
            if (_.some(initialCustomFields)) {
                setMaintenanteType('corrective');
            }
        }
    }, [props.location.query]);

    const syncParams = (asset_id) => {
        props.router.push(`/asset/${asset_id}`);
    };

    useEffect(() => {
        if (props.asset_id) {
            setAssetId(props.asset_id);
            syncParams(props.asset_id);
        }
    }, [props.asset_id]);

    const onChangeAsset = (id) => {
        setAssetId(id[0]);
        syncParams(id[0]);
    };

    const createEventAction = (evt) => {
        if (hasPerm('create_corrective_event')) {
            setSelectedEvent(null);
            evt.preventDefault();
            setEventFormVisible(true);
        }
    };

    const onCloseModal = () => {
        props.location.setQuery(null);
        setInitialCustomFields(null);
        setEventFormVisible(false);
        setMaintenanteType(null);
    };

    const createCallback = (event) => {
        onCloseModal();
        setNewEvent(event.id);
        if (event.impact_status > props.asset?.operational.operational_status) {
            if (hasPerm('aggravate_status')) {
                setStatusForm(event.impact_status);
            }
        }
        return ConfirmationDialog({
            confirmation_message: i18n.t('The maintenance event has been successfully created.'),
            buttons_text: ['Ok'],
        });
    };

    const onRowClick = (event) => {
        setEventFormVisible(true);
        setSelectedEvent(event.id);
    };
    const createReading = (evt) => {
        evt.preventDefault();
        const { id } = props.asset.info;
        const path = `/counterreading/#/${id}/reading`;
        window.location.href = path;
    };

    const CallHotlineWidget = () => (
        <div className={`call-hotline-widget${!props.hotline_number ? ' no-number' : ''}`}>
            <div><span className="glyphicon glyphicon-earphone" /></div>
            <span className="call-hotline-text">
                {
                    props.hotline_number ? (
                        <a href={`tel:${props.hotline_number}`}>
                            <span>
                                { i18n.t('Call hotline') }
                                {' '}
                                -
                                {' '}
                            </span>
                            <span>{ props.hotline_number }</span>
                        </a>
                    ) : (
                        i18n.t('No available hotline phone number')
                    )
                }
            </span>
            <span className="glyphicon glyphicon-chevron-right" />
        </div>
    );

    const onCloseStatusForm = () => {
        setStatusForm(null);
    };

    const operationalStatusCallback = (data) => {
        onCloseStatusForm();
        return ConfirmationDialog({
            confirmation_message: i18n.t('The operational status has been successfully Changed.'),
            buttons_text: ['Ok'],
        });
    };
    const withHomologation = props?.user_company?.has_homologated_assets;

    return (
        <div className={`driver-logbook-root ${props.device}`}>
            <div className="driver-logbook-holder">
                <div className="driver-logbook-top">
                    {
                        _locoPreparationSubmitted
                            && (
                                <Toast
                                    text={`${i18n.t('Your preparation has been successfully submitted')}`}
                                    onClick={(evt) => {
                                        if (evt) {
                                            evt.preventDefault();
                                            evt.stopPropagation();
                                        }
                                        setLocoPreparationSubmitted(null);
                                    }}
                                />
                            )
                    }
                    <div className="driver-logbook-header">
                        <div className="driver-logbook-top-title">{i18n.t('Consult digital logbook')}</div>
                        {
                            _assetId && !_loading
                                && <CallHotlineWidget />
                        }
                    </div>
                    <div className="driver-logbook-actions">
                        <div className="driver-logbook-asset-related">
                            <div className="driver-logbook-asset-selection">
                                <AssetSelector
                                    assets={props.assets}
                                    selected_asset={_assetId}
                                    onChange={onChangeAsset}
                                />
                            </div>
                            {
                                _assetId && !_loading
                                    && (
                                        <div className="driver-logbook-asset-status-holder">
                                            <div
                                                className={`driver-logbook-asset-status ${props.status_key} ${!_assetId ? 'disabled' : ''}`}
                                            >
                                                <span>{props.status}</span>
                                                {
                                                    props.status_text
                                                && <span>{props.status_text}</span>
                                                }
                                            </div>
                                            <CallHotlineButton
                                                onClick={() => setHotlineVisible(!_hotlineVisible)}
                                            />
                                            {
                                                _hotlineVisible
                                                && (
                                                    <div className="tooltip-hotline">
                                                        <CallHotlineWidget />
                                                    </div>
                                                )
                                            }
                                        </div>
                                    )
                            }
                            {
                                _assetId && !_loading && withHomologation && (
                                    <Homologations
                                        asset_id={_assetId}
                                    />
                                )
                            }
                        </div>
                        {
                            _assetId && !_loading
                                && (
                                    <div className="driver-logbook-buttons">
                                        <AddItemButton
                                            label={i18n.t('Create event')}
                                            primary
                                            onClick={hasPerm('create_corrective_event') && createEventAction}
                                            disabled={!hasPerm('create_corrective_event')}
                                        />
                                        <AddItemButton
                                            label={i18n.t('Add counter reading')}
                                            primary
                                            onClick={hasPerm('create_counter_readings') && createReading}
                                            disabled={!hasPerm('create_counter_readings')}
                                        />
                                    </div>
                                )
                        }
                    </div>
                </div>
                <div className="driver-logbook-bottom">
                    {
                        _assetId && !_loading ? (
                            <>
                                <div className="driver-logbook-bottom-left">
                                    <RestrictionsSection
                                        {...props}
                                        asset_id={_assetId}
                                    />
                                    <PreparationSection
                                        {...props}
                                        asset_id={_assetId}
                                        latestPreparations={latest_preparations}
                                        setPreparationTypeSelected={setPreparationTypeSelected}
                                        preparationTypes={_preparationTypes}
                                        hasPerm={hasPerm}
                                    />
                                    <MapSection
                                        {...props}
                                        asset_id={_assetId}
                                        hasPermToViewMap={hasPermToViewMap}
                                    />
                                    <DocumentsSection
                                        {...props}
                                        asset_id={_assetId}
                                    />
                                </div>
                                <div className="driver-logbook-bottom-right">
                                    <EventsSection
                                        {...props}
                                        asset_id={_assetId}
                                        onRowClick={onRowClick}
                                        newEvent={_newEvent}
                                    />
                                </div>
                            </>
                        ) : (
                            !_assetId ? (
                                <div className="driver-board-no-asset">
                                    <NoAsset
                                        title={i18n.t('No asset selected')}
                                        description={i18n.t('Please select an asset first')}
                                    />
                                </div>
                            ) : (
                                _loading === true
                                && (
                                    <div className="driver-board-loading">
                                        <Loader />
                                    </div>
                                )
                            )
                        )
                    }
                </div>
            </div>
            {
                _eventFormVisible
                    && (
                        <EventHub
                            asset={props.asset}
                            router={props.router}
                            event_id={_selectedEvent}
                            device={props.device}
                            params={props.params}
                            defaultCustomFields={_initialCustomFields}
                            viewer
                            onCloseModal={onCloseModal}
                            cancelCallback={onCloseModal}
                            onClickPrevious={onCloseModal}
                            createCallback={createCallback}
                            readOnly
                            event_type={_maintenanceType}
                        />
                    )
            }
            {
                _preparationTypeSelected
                    && (
                        <Modal
                            className="modal-loco-preparation"
                            layout={
                                props.device === LAYOUT.DESKTOP
                                    // eslint-disable-next-line no-undef
                                    ? MODAL_CONSTANTS.LAYOUT.NONE
                                    : MODAL_CONSTANTS.LAYOUT.FULL
                            }
                            position={MODAL_CONSTANTS.POSITION.RIGHT}
                            onClose={() => {
                                setPreparationTypeSelected(null);
                            }}
                            handleCloseInside
                        >
                            <LocoPreparation
                                asset_id={props.selected_asset_id}
                                asset_name={props.selected_asset_name}
                                preparation_type_id={_preparationTypeSelected.id}
                                preparation_type_name={_preparationTypeSelected.name}
                                callback={(d) => {
                                    setLocoPreparationSubmitted(d);
                                    setPreparationTypeSelected(null);
                                    fetchLatest();
                                }}
                                // 1:
                                // asset: 1
                                // id: 3
                                // is_conform: true
                                // steps: {v1_step1: {…}, v1_step2: {…}}
                                // timestamp: "2021-08-06T16:40:12.352515+01:00"
                                // type: 1
                                preparation_detail={latest_preparations[_preparationTypeSelected.id]}
                            />
                        </Modal>
                    )
            }
            {
                _statusForm
                && (
                    <Modal
                        layout={
                            props.device === LAYOUT.DESKTOP
                                ? MODAL_CONSTANTS.LAYOUT.NONE
                                : MODAL_CONSTANTS.LAYOUT.FULL
                        }
                        position={MODAL_CONSTANTS.POSITION.RIGHT}
                        onClose={onCloseStatusForm}
                    >
                        <div className="operational-status-modal">
                            <HeaderModal
                                title={i18n.t('Change current asset operational status')}
                                onCloseModal={onCloseStatusForm}
                            />
                            <OperationalStatusForm
                                asset_id={props.asset_id}
                                title={i18n.t('Change current asset operational status')}
                                textSubmitRTO={i18n.t('Change operational status and Return to operation')}
                                textSubmit={i18n.t('Confirm change of operational status')}
                                operationalStatusCallback={operationalStatusCallback}
                                impact_status_id={_statusForm}
                                choice={false}
                                overrideDevice={LAYOUT.MOBILE}
                                autoRefresh
                                noPermMessage
                                onCancel={onCloseStatusForm}
                                handleCloseInside
                                noScreenshot
                            />
                        </div>
                    </Modal>
                )
            }
            { props.children }
        </div>
    );
};

const AssetSelector = (props) => (
    <SelectFilter
        name="Asset"
        values={props.assets}
        onChange={props.onChange}
        onClear={props.onClear}
        selected={props.selected_asset}
        id_getter={(a) => a.id}
        labelizer={(a) => `${a.name} [${a.class_name}]`}
        inline_label={i18n.t('Asset')}
        is_asset_SelectFilter
        label={i18n.t('Select an asset')}
        virtual
        clearable={false}
    />
);

const NoAsset = () => (
    <div className="nothing-selected-holder">
        <img className="nothing-selected" src={noAssetSelectedImg} alt="no asset selected" />
        <div className="title">{i18n.t('No asset selected')}</div>
        <div className="sub-title">{i18n.t('Please select an asset first')}</div>
    </div>
);

const RestrictionsSection = (props) => (
    <div className="driver-logbook-restrictions">
        <RestrictionCardList
            asset_id={props.asset_id}
            restriction_state="open"
        />
    </div>
);

const PreparationSection = (props) => {
    if (!props.preparationTypes || props.preparationTypes.length === 0) return null;
    return (
        <div className="driver-logbook-preparations">
            {
                props.preparationTypes.map((preparation_type) => {
                    const latest_preparation = props.latestPreparations[preparation_type.id];
                    const remainingTime = latest_preparation && latest_preparation.valid_until ? moment(latest_preparation.valid_until).diff(moment(), 'hours') : null;
                    return (
                        <PreparationCard
                            key={preparation_type.id}
                            content={preparation_type.name}
                            remainingTime={remainingTime !== null ? remainingTime : null}
                            lastExecution={latest_preparation && latest_preparation.timestamp}
                            conform={latest_preparation && latest_preparation.is_conform}
                            onClick={() => {
                                if (!latest_preparation) {
                                    if (props.hasPerm('create_preparation')) {
                                        props.setPreparationTypeSelected(preparation_type);
                                    }
                                } else if (props.hasPerm('view_preparation')) {
                                    props.setPreparationTypeSelected(preparation_type);
                                }
                            }}
                        />
                    );
                })
            }
        </div>
    );
};

const MapSection = (props) => {
    if (!props.hasPermToViewMap) {
        return (
            <div className="no-perm-map">
                {i18n.t('You do not have the permission to view the map')}
            </div>
        );
    }
    return (
        <div className="driver-logbook-map">
            <AssetTrackOnMap
                asset_id={props.asset_id}
            />
        </div>
    );
};

const EventsSection = (props) => (
    <div className="driver-logbook-events">
        <div className="driver-logbook-events-opened">
            <div>{i18n.t('OPENED')}</div>
            <EventList
                asset_id={props.asset_id}
                result="open"
                deviceOverride={props.device === 'mobile' ? 'mobile' : 'desktop'}
                onRowClick={props.onRowClick}
                refresh={props.newEvent}
            />
        </div>
        <div className="driver-logbook-events-closed">
            <div>{i18n.t('CLOSED')}</div>
            <EventList
                asset_id={props.asset_id}
                result="closed"
                deviceOverride={props.device === 'mobile' ? 'mobile' : 'desktop'}
                onRowClick={props.onRowClick}
                refresh={props.newEvent}
            />
        </div>
    </div>
);

const DocumentsSection = (props) => {
    if (!props.component_structure) return null;
    return (
        <DocumentsList
            asset_id={props.asset_id}
            title={i18n.t('Documentation')}
            driverlogbook
        />
    );
};

const DriverLogbook = (props) => (
    <MultiDeviceLayout>
        <DriverLogbookComponent
            {...props}
        />
    </MultiDeviceLayout>
);
const mapStateToProps = () => {
    const maintenance_selector = maintenanceEventsSelectors
        .makeCorrectiveMaintenanceEventsForAsset();
    const restrictions_selector = restrictionsSelectors.makeRestrictionsListForAsset();
    const getMaintenanceSettings = assetsSelectors.makeMaintenanceSettingsForAsset();
    const status_selector = assetOperationalStatusSelectors.makeStatusListForAsset();
    const getAllAssetData = assetsSelectors.makeAllAssetData();
    return (state, props) => {
        const asset_id = props.params && props.params.asset_id;
        const asset = getAllAssetData(state, asset_id);
        const component_structure = asset && asset.info && asset.info.component_structure;
        const selected_asset_id = asset && asset.info.id;
        const selected_asset_name = asset && asset.info.name;
        const hotline_number = asset && asset.info && asset.info.hotline_number;
        const maintenance_settings = (asset && asset.info)
            ? getMaintenanceSettings(state, asset.info.id)
            : {};
        const operational_detailed_status = (asset && asset.operational)
            ? asset.operational.operational_detailed_status
            : null;
        const operational_status_type = maintenance_settings
            ? maintenance_settings.operational_status_type
            : {};
        const detailed_operational_status_type = (maintenance_settings
            && maintenance_settings.detailed_operational_status_type)
            ? maintenance_settings.detailed_operational_status_type[operational_detailed_status] : {};
        const operational_status_id = asset && asset.operational
            ? asset.operational.operational_status
            : null;
        const operational_status = (operational_status_id && operational_status_type
            && operational_status_type.hasOwnProperty(operational_status_id))
            ? operational_status_type[operational_status_id] : {};
        const status_key = operational_status && operational_status.key;
        const check_user_perms = usersSelectors.userHasPerm(state);

        const status = operational_status && operational_status.name;
        const status_text = detailed_operational_status_type
            && detailed_operational_status_type.name;
        return {
            asset,
            component_structure,
            selected_asset_id,
            selected_asset_name,
            hotline_number,
            maintenance_settings,
            operational_detailed_status,
            operational_status_type,
            detailed_operational_status_type,
            operational_status,
            status_key,
            status,
            status_text,
            // show all accessible assets
            assets: assetsSelectors.getAssetsInfos(state),
            filter_fields: filteringCollectionsSelectors.getCollectionFields(state, 'driver_logbook'),
            restrictions: (asset && asset.info) ? restrictions_selector(state, asset.info.id) : {},
            events: (asset && asset.info) ? maintenance_selector(state, asset.info.id) : {},
            statuses: (asset && asset.info) ? status_selector(state, asset.info.id) : [],
            users: state.users.all,
            check_user_perms,
            push_infos: uxSelectors.getPushInfos(state),
            can_create_reading: usersSelectors.userHasPerm(state)('create_counter_readings'),
            user_company: usersSelectors.getCurrentUserCompany(state),
        };
    };
};

const mapDispatchToProps = (dispatch) => ({
    fetchStatusForAsset: (asset_id) => dispatch(assetOperationalStatusOperations.fetchForAsset(asset_id)),
    fetchMaintenanceEventsForAsset: (asset_id) => dispatch(maintenanceEventsOperations.fetchForAsset(asset_id)),
    fetchRestrictionsForAsset: (asset_id) => dispatch(restrictionsOperations.fetchForAsset(asset_id)),
    setPushInfos: (push_infos, from = null) => dispatch(uxOperations.setPushInfos(push_infos, from)),
    setFilterField: (f, p) => dispatch(filteringCollectionsOperations.setField('driver_logbook', f, p)),
});

export default withNavigate(connect(mapStateToProps, mapDispatchToProps)(DriverLogbook));
