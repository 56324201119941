export default {
    LAYOUT: {
        NONE: 'none',
        FULL: 'full',
        TWO_THIRD: 'two-third',
        ONE_THIRD: 'one-third',
    },
    POSITION: {
        LEFT: 'left',
        RIGHT: 'right',
    },
};
